/*
    Mellanblå: #E9EBF0
    Länkblå: $color-linkblue
    Mörkblå $color-darkblue
    Mörkgrå $color-darkgray
    Bakgrundsgrå #F8F8F8
    Ljusgrå #BABA
    Grön #499C7E
    Blå #5F89B4

*/

body {
  /*  font: 16px/24px 'Open Sans', sans-serif;*/
  font-family: 'AvenirNextLTProRegular' !important;
  color: $color-darkgray;
  font-weight: 400;
}

input {
  font-family: 'AvenirNextLTProRegular' !important;
  color: $color-darkgray;
  font-weight: 400;
}

p {
  font-family: 'AvenirNextLTProRegular' !important;
  margin: 0px 0px 20px 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $color-darkgray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'AvenirNextLTProDemi' !important;
  font-weight: 900;
  margin-top: 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
}

button {
  font-family: 'AvenirNextLTProDemi' !important;
}

h1 {
  letter-spacing: -1px;
}
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.2px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 15px;
  margin-top: 0px;
  color: $color-darkgray;
}

h2 {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
  color: $color-darkgray;
}

h3 {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 10px;
  color: $color-darkgray;
  &:focus {
    text-decoration: underline !important;
  }
}

h4 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 9px;
  color: $color-darkgray;
}

h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 9px;
  color: $color-darkgray;
}

h6 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 9px;
  font-style: italic;
  color: $color-darkgray;
}

a {
  font-family: 'AvenirNextLTProDemi' !important;
  color: $color-linkblue;
  text-decoration: none;
  outline: 0;
  font-size: 18px;

  &:focus {
    text-decoration: underline !important;
  }
}

a:hover {
  text-decoration: underline;
  outline: 0;
}

a h3:hover,
a h4:hover {
  text-decoration: underline;
}

p a,
p a:visited {
  line-height: inherit;
}

.ingress {
  font-weight: 400;
  font-family: 'AvenirNextLTProDemi' !important;
  font-size: 20px;
  line-height: 26px;
  color: $color-darkgray;
  font-weight: 900;
}

.imagecaption {
  font-family: 'AvenirNextLTProLight' !important;
  font-size: 15px;
  line-height: 20px;
  color: $color-darkgray;
}

.navigationlinks {
  font-family: 'AvenirNextLTProMedium' !important;
  font-size: 20px;
  color: $color-linkblue;
  transition: all 0.15s ease-in-out;
}

.topnavigationlinks a {
  font-family: 'AvenirNextLTProMedium' !important;
  color: $color-linkblue;
  font-size: 15px;
  font-weight: 600;
}

.publishlabel {
  font-family: 'AvenirNextLTProMedium' !important;
  font-size: 14px;
  color: $color-darkgray;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  margin-left: 30px;
}

@font-face {
  font-family: 'pfficon';
  src: url('../Fonts/pfficon.eot?o6q4au');
  src: url('../Fonts/pfficon.eot?#iefixo6q4au') format('embedded-opentype'), url('../Fonts/pfficon.woff?o6q4au') format('woff'),
    url('../Fonts/pfficon.ttf?o6q4au') format('truetype'), url('../Fonts/pfficon.svg?o6q4au#pfficon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTProHeavy';
  src: url('../fonts/AvenirNextLTProHeavy/font.woff') format('woff'), url('../fonts/AvenirNextLTProHeavy/font.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTProMedium';
  src: url('../fonts/AvenirNextLTProMedium/font.woff') format('woff'), url('../fonts/AvenirNextLTProMedium/font.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTProLight';
  src: url('../fonts/AvenirNextLTProLight/font.woff') format('woff'), url('../fonts/AvenirNextLTProLight/font.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTProRegular';
  src: url('../fonts/AvenirNextLTProRegular/font.woff') format('woff'), url('../fonts/AvenirNextLTProRegular/font.woff2') format('woff2');
}

@font-face {
  font-family: 'AvenirNextLTProBold';
  src: url('../fonts/AvenirNextLTProBold/font.woff') format('woff'), url('../fonts/AvenirNextLTProBold/font.woff2') format('woff2');
}
@font-face {
  font-family: 'AvenirNextLTProDemi';
  src: url('../fonts/AvenirNextLTProDemi/font.woff') format('woff'), url('../fonts/AvenirNextLTProDemi/font.woff2') format('woff2');
}

/*MOBILE STYLES*/

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  h1 {
    font-size: 32px;
    line-height: 36px;
  }

  h2 {
    font-size: 30px;
    line-height: 34px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
}
