﻿/* SEARCHPAGE */

.search_result {
  margin-bottom: 6px;
}

/* override general ul's */
.search_result ul {
  margin: 0;
}

.search_result ul li {
  text-indent: 0;
}

.search_result ul li:before {
  content: none;
}

.page .search_result li {
  padding: 22px 20px 23px 20px;
  background: #fff;
  border-top: 1px solid #b6b6b7;
  font: 14px/22px 'Roboto', sans-serif;
  font-weight: 300;
  max-height: 120px;
}

.page .search_result li:last-child {
  border-bottom: 1px solid #b6b6b7;
}

.search_result .result_hits:after,
.search_result .result_hits:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.search_result .fa {
  color: #d4064f;
  font-size: 22px;
  position: absolute;
  right: 15px;
}

.search_result .fa:before {
  position: relative;
  top: 5px;
}

/*.page .search_result li a[href*='.pdf']:after{
	content: "\65";
	display: inline-block;
	float:right;
	right: 18px;
	position: absolute;
    font-family: 'PFFicon';
    font-size: 30px;
    font-style: normal;
    color:#D4064F;
}*/

.page .search_result li h3 {
  margin-bottom: 5px;
}

.page .search_result li a {
  text-decoration: none;
}

.page .search_result li a:hover {
  text-decoration: underline;
}
