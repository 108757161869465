﻿/* HEADER GLOBAL SEARCH */

.header_wrap .search-box {
    padding-top: 7px;
}

    .header_wrap .search-box #quick-search-query {
        height: 40px;
        width: 260px;
        padding-left: 15px;
        border-radius: 0;
        border: 1px solid #ccc;
    }

        .header_wrap .search-box #quick-search-query:hover {
            background-color: #EBF0F4;
        }

        .header_wrap .search-box #quick-search-query:focus {
            background-color: white;
        }

    .header_wrap .search-box .fa-search-wrap {
        display: inline-block;
        vertical-align: top;
        height: 40px;
        width: 40px;
        background-color: #ebf0f4;
        -webkit-transition: background-color linear 0.15s;
        transition: background-color linear 0.15s;
    }

        .header_wrap .search-box .fa-search-wrap:hover {
            background-color: #FAB51E !important;
        }

body[data-page=search-page] .header_wrap .search-box .fa-search-wrap {
    background-color: #4D5B64;
}

    body[data-page=search-page] .header_wrap .search-box .fa-search-wrap .fa-search {
        color: white;
    }

.header_wrap .search-box .fa-search-wrap .fa-search {
    vertical-align: top;
    font-size: 20px;
    position: relative;
    left: -10px;
    top: 9px;
    color: #4D5B64;
}

.header_wrap .search-box .fa-search-wrap:hover .fa-search {
    color: white;
}

.header_wrap .search-box .fa-search-wrap.quick-search-query-has-focus {
    background-color: #FAB51E !important;
}

    .header_wrap .search-box .fa-search-wrap.quick-search-query-has-focus .fa-search {
        color: white;
    }