﻿/* COLOR CLASSES */

.bg_grey {
    background: #e6ece7;
    border: 0;
    /*box-sizing: border-box;*/
}

.bg_white {
	background: #FFF;
}

.bg_blue {
	background: #528AB8;
}

.bg_lightblue {
	background: #83B3D3;
}

.bg_green {
	background: #599674;
}

.bg_lightgreen {
	background: #86BA95;
}

/* NEW */

/* Primary Colors */

.bg-white {
    background-color:white;
}

.bg-black {
    background-color:black;
}

.bg-blue {
    background-color:#047ec7;
}

.bg-blue-bottom {
    background-color:#99B6C7;
}

.bg-yellow {
    background-color:#FAB51E;
}

.bg-yellow-bottom {
    background-color:#FCD47E;
}

.bg-darkgrey {
    background-color:#666666;
}

.bg-grey {
    background-color:#cccccc;
}

.bg-justwhite {
    background-color:#F9F9F9;
}

/* Secondary Colors */

.bg-red {
    background-color:#D4064F;
}

.bg-red-bottom {
    background-color:#E56A95;
}

.bg-metal {
    background-color:#4D5B64;
}

.bg-metal-light {
    background-color:#738995;
}

.bg-blue-light {
    background-color:#adc5d2;
}

.bg-cyan {
    background-color:#c2d3dd;
}

.bg-cyan-light {
    background-color:#d6e2e9;
}

.bg-cyan-light-light {
    background-color:#ebf0f4;
}