.header_wrap nav.global li:first-child a:before {
  font-family: 'pfficon' !important;
}

.footer_wrap {
  background: $color-footer-primary !important;
}

.navheader-flex {
  background: $color-linkblue;
}

.navigationlinks,
.topnavigationlinks a {
  color: white;
}

.header_wrap nav.global li a {
  color: $color-linkblue;
}

#topheader {
  /*padding: 0 150px;*/
  @include mobile {
    &[aria-expanded='true'] {
      border-bottom: 2px solid $color-linkblue;
    }
    border-bottom: 2px solid $color-border-primary;
  }
}

.text_area {
  text-align: center;
}

.small-block-header {
  color: $color-secondary;
  margin-bottom: 10px;
  font-size: 21px;
}

.footer_wrap_title {
  color: $color-darkgray;
}

.footer_wrap_flex {
  & li {
    width: calc(25%);
    margin-right: 0;

    @include mobile {
      width: 100%;
    }
  }

  & li:last-child {
    ul {
      border: none;
    }
    margin: 0 !important;
  }
  ul li p {
    color: $color-darkgray;
  }

  ul li h3 {
    font-size: 15px;
  }

  &__border {
    border-right: 1px solid $color-border-primary;
    padding: 0 30px 20px !important;

    @include mobile {
      border-right: none;
      border-bottom: 1px solid $color-border-primary;
      margin-bottom: 30px !important;
    }
  }
}

.footer_wrap .information p {
  width: 100%;
  @include mobile {
    width: 100%;
  }
}

.footer_wrap .information h3 {
  color: $color-darkgray;
}

.footer-wrap nav ul li ul {
  margin: 0 20px 0 0 !important;
  max-width: unset;
}

.footer_wrap .information nav ul li.selected ul li a {
  color: $color-linkblue;
}

.footer_wrap .information nav ul li a {
  color: $color-linkblue;
}

.block_four {
  @include mobile {
    text-align: center;
  }
  & section {
    width: 25%;
    background: transparent;
    @include mobile {
      width: 100%;
    }
  }
}

.header_wrap .search_box input[type='search'] {
  background: #fff url(../Images/search-pff.svg) no-repeat 19px 19px;
}

.footer_wrap .information nav ul li ul {
  margin-right: 0;
}


.header_wrap .logotype img {
    width: 500px;
    height: 40px;

    @include mobile {
        width: 100%;
        height: 100%;
    }
}

.article_content_paragraph {
    div {

    }
}

.info-block {
    padding: 20px !important;
    background: $color-highlight !important;
}


.small-block-header_nav {
    color: $color-darkgray !important;
    font-size: 18px !important;
    font-weight: 600;
}
.list-block-nav {
    padding: 20px !important;
    background: $color-highlight !important;

    ul {
        padding: 10px 0 !important;

        li {
            border-top: 1px solid $color-border-primary !important;
            padding: 15px 0 !important;
            list-style-type: none !important;

            a {
                display: block !important;
                text-align: left !important;
                margin: 8px 0 !important;
            }
        }
    }
}
.article_content_paragraph .info-block {
    margin-bottom: 28px;
}

.link_block_nav {
    padding: 15px 0 !important;
    position: relative;

    img {
        position: absolute;
        right: 0;
        top: 15px;
        height: 20px;
        width: 21px !important;
    }
}
 