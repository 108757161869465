// BEGIN COLORS
$color-blue: #499c7e;
$color-midblue: #ffffff;
$color-linkblue: #0e68b8;
$color-linkblue-disabled: rgba(14, 104, 184, .5);
$color-highlight: #e7eef4;
$color-darkblue: #1b3d5a;
$color-darkgray: #454545;
$color-lightgray: #baba;
$color-backgroundgray: #f8f8f8;
$color-inverted: #375e7f;
$color-white: #ffffff;
$color-textshadow: #f0f0f0;
$color-secondary: #d4064f;
$color-footer-primary: #e7eef4;
$color-black: #000;

// BORDERS
$color-border-primary: #baba;

// COOKIEBAR
$color-cookie-off: #d4064f;

// END COLORS

// PADDING
$base-padding: 30px;
$base-pagepadding: 0 30px;
$base-verticalpadding: 30px 0;

// PADDING MOBILE
$base-padding-mobile: 20px;
$base-pagepadding-mobile: 0 30px;

// MEDIA QUERIES
$tablet-width: 1124px;
$desktop-width: 1378px;
